var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("review-cust-will-info")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12"
  }, [_c('div', {
    staticClass: "card bg-light rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('form', {
    staticClass: "login-form",
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('img', {
    staticClass: "avatar avatar-large-logo d-block mx-auto",
    attrs: {
      "src": _vm.logo,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "form-group position-relative mt-5"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newIc,
      expression: "newIc"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('mykad'),
      "name": ""
    },
    domProps: {
      "value": _vm.newIc
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.newIc = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("old-ic")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.oldIc,
      expression: "oldIc"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('old-ic'),
      "name": ""
    },
    domProps: {
      "value": _vm.oldIc
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.oldIc = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.fullname,
      expression: "fullname"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('fullname'),
      "name": ""
    },
    domProps: {
      "value": _vm.fullname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.fullname = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary"
  }, [_vm._v(_vm._s(_vm.$t("search")))])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }